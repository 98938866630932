import React, { useState } from "react"
import WithMeeting from "./WithMeeting/WithMeeting"
import WithoutMeeting from "./WithoutMeeting/WithoutMeeting"
import "react-tooltip/dist/react-tooltip.css"
import "./CreateOrder.scss"

export default function CreateOrder() {

  const [isWithMeeting, setIsWithMeeting] = useState(true)

  return (
    <>
      <div className="pagetitle d-flex mb-4">
        <h1 className="me-5 d-flex align-items-center">Create Order</h1>
        <div className={`btn toggle-btn px-2 py-1 me-3 ${isWithMeeting ? "active" : ""}`} onClick={() => setIsWithMeeting(true)}>With Meeting</div>
        <div className={`btn toggle-btn px-2 py-1 ${isWithMeeting ? "" : "active"}`} onClick={() => setIsWithMeeting(false)}>Without Meeting</div>
      </div>
      <div>
        {isWithMeeting ? <WithMeeting /> : <WithoutMeeting />}
      </div>
    </>
  )
}
