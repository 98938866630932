import { createSlice } from "@reduxjs/toolkit"
import moment from "moment"
import { localStorageLocationKey } from "../../const"

const initialState = {
    inputUser: {},
    inputLocation: localStorage.getItem(localStorageLocationKey) ? JSON.parse(localStorage.getItem(localStorageLocationKey)) : [],
    inputDate: moment().format("YYYY-MM-DD"),
    inputStatus: [],
    inputDateRange: [moment()._d, moment()._d]
}

export const CateringOrderInputStatesSlice = createSlice({
    name: "API",
    initialState,
    reducers: {
        changeUserValue: (state, action) => {
            state.inputUser = action.payload
        },
        changeLocationValue: (state, action) => {
            state.inputLocation = action.payload
        },
        changeDateValue: (state, action) => {
            state.inputDate = action.payload
        },
        changeStatusValue: (state, action) => {
            state.inputStatus = action.payload
        },
        changeDateRangeValue: (state, action) => {
            state.inputDateRange = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { changeUserValue, changeLocationValue, changeDateValue, changeStatusValue, changeDateRangeValue } = CateringOrderInputStatesSlice.actions

export default CateringOrderInputStatesSlice.reducer
