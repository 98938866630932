import toast from "react-hot-toast"

export const handleError = (response) => {
    if (response) {
        if (typeof response === "string") {
            console.warn(response)
            toast.error(response)
        } else if (response?.ExceptionMessage || response?.Message) {
            console.warn(response)
            toast.error(response?.ExceptionMessage || response?.Message)
        }
    }
}