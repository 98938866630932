export const router = {
  login: "/login",
  getCateringUser: "/CanteenUser",
  userImage: "/Colleague/UserImage",
  searchColleague: "/Colleague/Search",
  location: "/Admin/Catering/Locations",
  meetingRoomAvailability: "/MeetingRoom",
  meetingRoomByLocation: "/Admin/Catering/Meetingrooms",
  meetingData: "/Meetings/WithShared",
  catering: "/Catering",
  getCateringOrderStatus: "/Catering/Status",
  getCateringWithFilter: "/Admin/Catering/Tenant",
  AdminCatering: "/Admin/Catering",
  Order: "/Admin/Catering/Order",
  department: "/Department",
  currentUser: "/Permissions/CurrentUser",
  invoiceExternal: "/Admin/Catering/InvoiceExternal"
}