import React, { useEffect, useState } from "react"
import { deleteCanteenUser, getCanteenUser, getCanteenUserProfile, saveCanteenUser, searchColleague, } from "../../services/ApiServices"
import { changeAllCanteenUsersValue } from "../../store/slices/APIResponseSlice"
import { confirmAlert } from "react-confirm-alert"
import { useDispatch, useSelector } from "react-redux"
import { handleError } from "../../const"
import toast from "react-hot-toast"
import moment from "moment/moment"
import ClipLoader from "react-spinners/ClipLoader"

// ==== Imported Image ====
import iconUser from "../../assets/img/icon-user.svg"
import search from "../../assets/img/icon-search.svg"
import add from "../../assets/img/icon-add.svg"
import trash from "../../assets/img/icon-trash.svg"

export default function User() {
  // const AllCanteenUsers = useSelector((state) => state?.APIResponse?.AllCanteenUsers)

  // =============== Local States ===============
  const [userData, setUserData] = useState([])
  const [searchData, setSearchData] = useState(false)
  const [loading, setLoading] = useState(true)
  const [searchList, setSearchList] = useState([])
  const [searchTxt, setSearchTxt] = useState("")
  const [allUserProfile, setAllUserProfile] = useState({})

  const dispatch = useDispatch()

  const GetUserProfile = async (email) => {
    if (allUserProfile[email] === undefined) {
      setAllUserProfile((prev) => ({ ...prev, [email]: {} }))
      const response = await getCanteenUserProfile(email)
      setAllUserProfile((prev) => ({ ...prev, [email]: response }))
    }
  }

  const getCanteenUserData = async () => {
    setLoading(true)
    const response = await getCanteenUser()
    if (response?.Data && typeof response?.Data === "object" && response?.Data?.length >= 0) {
      dispatch(changeAllCanteenUsersValue(response?.Data))
      response?.Data?.map(async (d) => {
        GetUserProfile(d?.Email)
      })
      setUserData(response?.Data)
    } else {
      handleError(response)
    }

    setLoading(false)
  }

  const saveUser = async (name, email) => {
    const requestPayload = {
      Name: name,
      Email: email,
    }
    setSearchData(false)
    await saveCanteenUser(requestPayload).then((response) => {
      if (response) {
        setSearchTxt("")
        getCanteenUserData()
        toast.success("User added successfully.")
      } else {
        toast.error("Error while adding user!")
      }
    }).catch((error) => {
      handleError(error)
    })
  }

  const deleteUser = async (email) => {
    const resp = await deleteCanteenUser(email)
    await getCanteenUserData()
    if (resp === true) {
      toast.success("User removed successfully.")
    } else {
      toast.error("Error while removing user!")
    }
  }

  useEffect(() => {
    let subscribed = true

    if (searchTxt !== "") {
      searchColleague(searchTxt, moment().format("YYYY-MM-DD")).then(async (resp) => {
        if (subscribed) {
          if (resp?.length > 0) {
            setSearchData(true)
            resp?.map(async (d) => {
              GetUserProfile(d?.Email)
            })
            setSearchList(resp)
          } else {
            setSearchData(false)
            setSearchList([])
          }
        }
      })
    } else {
      if (subscribed) {
        setSearchData(false)
        getCanteenUserData()
      }
    }

    return () => {
      subscribed = false
    }
  }, [searchTxt])

  return (
    <>
      <section className="section mb-4">
        <div className="inputSec-icon">
          <input
            value={searchTxt}
            onChange={(e) => setSearchTxt(e.target.value)}
            className="form-control mb-3" placeholder="Find"
          />
          <span><img src={search} alt="icon-search" /></span>
        </div>

        <div className="card">
          <div className="card-content">
            <div className="table-responsive table-custom">
              {loading && (
                <div className="d-flex align-items-center justify-content-center w-100" style={{ height: "500px" }}>
                  <ClipLoader
                    loading={loading}
                    size={50}
                    color="blue"
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              )}

              <table className="table table-hover valignM list table-user mb-0">
                <tbody>
                  {!searchData && !loading && userData && userData?.length !== 0 && (
                    userData?.map((user, i) =>
                      <tr key={i}>
                        <td>
                          <div className="icon-md d-inline-block mt-2">
                            <img
                              src={allUserProfile[user?.Email] && allUserProfile[user?.Email]?.Image ? allUserProfile[user?.Email]?.Image : iconUser}
                              alt="Profile"
                              className="rounded-circle"
                            />
                          </div>
                        </td>
                        <td>
                          <div className="userInfo d-inline-block">
                            <span>{user?.Name}</span>
                            <span className="txt-sm txt-gray">{user?.Email}</span>
                          </div>
                        </td>
                        <td className="text-end">
                          <button className="btn btn-secondary btn-icon">
                            <img
                              alt="ico-trash" src={trash}
                              onClick={() =>
                                confirmAlert({
                                  title: "Confirm to submit",
                                  message: "Are you sure you want to remove this user?",
                                  buttons: [
                                    {
                                      label: "Yes",
                                      onClick: () => deleteUser(user?.Id),
                                    },
                                    {
                                      label: "No",
                                      onClick: () => { },
                                    },
                                  ],
                                })
                              }
                            />
                          </button>
                        </td>
                      </tr>
                    )
                  )}

                  {searchData && !loading && searchList && searchList?.length > 0 && (
                    searchList?.map((user, i) =>
                      <tr key={i}>
                        <td>
                          <div className="icon-md d-inline-block mt-2">
                            <img
                              src={allUserProfile[user?.Email] && allUserProfile[user?.Email]?.Image ? allUserProfile[user?.Email]?.Image : iconUser}
                              className="rounded-circle" alt="Profile"
                            />
                          </div>
                        </td>
                        <td>
                          <div className="userInfo d-inline-block">
                            <span>{user?.Name}</span>
                            <span className="txt-sm txt-gray">{user?.Email}</span>
                          </div>
                        </td>
                        <td className="text-end">
                          <button className="btn btn-secondary btn-icon">
                            <img
                              alt="icon-add"
                              src={add}
                              onClick={() => {
                                if (userData?.filter((data) => data?.Email === user?.Email)?.length > 0) {
                                  setSearchData(false)
                                  setSearchTxt("")
                                  toast.error("User is already exist!")
                                } else {
                                  confirmAlert({
                                    title: "Confirm to submit",
                                    message: "Are you sure to add this user.",
                                    buttons: [
                                      {
                                        label: "Yes",
                                        onClick: () =>
                                          saveUser(user?.Name, user?.Email),
                                      },
                                      {
                                        label: "No",
                                        onClick: () => { },
                                      },
                                    ],
                                  })
                                }
                              }}
                            />
                          </button>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
